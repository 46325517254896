import { getMediaDocument } from '@/api/media.js';

export const downloadDocs = async (url, fileName) => {
  const res = await getMediaDocument(url);
  const fileURL = window.URL.createObjectURL(new Blob([res.data]));
  const fileLink = document.createElement('a');
  const type = res.data.type;
  const format = type.slice(type.lastIndexOf('/') + 1);

  console.log(fileURL);
  fileLink.href = fileURL;
  fileLink.setAttribute('download', `${fileName}.${format}`);
  document.body.appendChild(fileLink);
  fileLink.click();
};
