import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import InputDate from '@/components/InputDate.vue';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import MainSelect from '@/components/UI/MainSelect.vue';
let DialogModal = class DialogModal extends Vue {
    constructor() {
        super(...arguments);
        this.documentData = {
            name: null,
            comment: null,
            type_doc: 0,
            id: 0,
            date: '',
            files: [],
            created_at: '',
            allow_processing: false,
            laboratory: '',
            laboratory_id: null,
        };
        this.selectedDocType = null;
        this.selectedType = '';
        this.selectedLab = '';
    }
    changeLocalDatas(val) {
        this.documentData = { ...val };
        this.selectedDocType = this.documentData.type_doc;
        this.selectedType = this.documentTypes.find((documentType) => documentType.value === this.selectedDocType);
        this.selectedLab = this.laboratoriesList.find((lab) => lab.id === this.documentData.laboratory_id);
    }
    mounted() {
        this.changeLocalDatas(this.document);
    }
    get documentTypes() {
        return this.$store.state.staticVariables.staticVariables?.document_types || [];
        ;
    }
    get laboratoriesList() {
        return this.$store.state.staticVariables.labs;
    }
    closeModal() {
        return false;
    }
    editDocument() {
        const { date, name, type_doc, laboratory_id, comment } = this.documentData;
        return {
            date,
            name,
            type_doc,
            laboratory_id,
            comment: comment || ''
        };
    }
    selectType(value) {
        // if (this.document.type_doc === 1) {
        //   this.$store.dispatch(
        //     'error/showErrorNotice',
        //     { message: 'Расшифрованные анализы будут отвязаны от документа, но сохраняться в системе' },
        //     { root: true }
        //   );
        // }
        this.documentData.type_doc = value.value;
        this.selectedType = { description: value.description, value: value.value };
    }
    changeDate(value) {
        this.documentData.date = value;
    }
    selectLab(value) {
        this.documentData.laboratory_id = value.id;
        this.selectedLab = value.name;
    }
};
__decorate([
    Prop({ required: true })
], DialogModal.prototype, "isEditDocumentModalOpen", void 0);
__decorate([
    Prop({ required: true })
], DialogModal.prototype, "document", void 0);
__decorate([
    Prop({ type: Boolean, default: false })
], DialogModal.prototype, "hideSkipBtn", void 0);
__decorate([
    Watch('document', { deep: true })
], DialogModal.prototype, "changeLocalDatas", null);
__decorate([
    Emit('close-modal')
], DialogModal.prototype, "closeModal", null);
__decorate([
    Emit('edit-document')
], DialogModal.prototype, "editDocument", null);
DialogModal = __decorate([
    Component({
        components: { MainSelect, MainBtn, CloseBtn, InputDate },
    })
], DialogModal);
export default DialogModal;
