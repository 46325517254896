import { __decorate } from "tslib";
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import MainBtn from '@/components/UI/buttons/MainBtn.vue';
import CloseBtn from '@/components/UI/buttons/CloseBtn.vue';
import DialogModal from '@/components/modals/DialogModal.vue';
import { downloadDocs } from '@/utils/downloadDocs.js';
import ZoomImg from '@/components/ZoomImg.vue';
let FileListSliderModal = class FileListSliderModal extends Vue {
    constructor() {
        super(...arguments);
        this.showErrorReason = false;
        this.currentPage = 1;
        this.isDialogModalOpen = false;
    }
    setInitialPage(val) {
        this.currentPage = val;
    }
    closeModal() {
        this.showErrorReason = false;
        return false;
    }
    get countIsPdf() {
        const link = this.fileList && this.fileList[this.currentPage - 1]?.file_link;
        return link?.indexOf('.pdf') !== -1;
    }
    get countPages() {
        return this.fileList?.length;
    }
    toggleDialogModal(val) {
        this.isDialogModalOpen = val;
    }
    btnPrevClick() {
        this.currentPage--;
    }
    btnNextClick() {
        this.currentPage++;
    }
    async deleteFile() {
        const payload = {
            documentId: this.documentId,
            fileId: this.fileList[this.currentPage - 1].id,
        };
        const isResult = await this.$store.dispatch('storage/deleteFile', payload);
        this.$store.dispatch('storage/loadDocument', this.documentId);
        this.isDialogModalOpen = false;
        this.closeModal();
    }
    downloadFile() {
        const { file_link, id } = this.fileList[this.currentPage - 1];
        downloadDocs(file_link, id);
    }
    openPdfFile() {
        const link = this.currentImageSrc;
        window.open(link, '_blank');
    }
    get currentImageSrc() {
        const { fileList, currentPage } = this;
        return fileList?.[currentPage - 1]?.file_link;
    }
    get currentErrorReason() {
        const { fileList, currentPage } = this;
        return fileList?.[currentPage - 1]?.reason;
    }
};
__decorate([
    Prop({ required: true })
], FileListSliderModal.prototype, "isFileListSliderModalOpen", void 0);
__decorate([
    Prop({ required: true })
], FileListSliderModal.prototype, "fileList", void 0);
__decorate([
    Prop({ required: true })
], FileListSliderModal.prototype, "documentId", void 0);
__decorate([
    Prop({ default: 1 })
], FileListSliderModal.prototype, "initialPage", void 0);
__decorate([
    Watch('initialPage')
], FileListSliderModal.prototype, "setInitialPage", null);
__decorate([
    Emit('close-modal')
], FileListSliderModal.prototype, "closeModal", null);
FileListSliderModal = __decorate([
    Component({
        components: { DialogModal, MainBtn, CloseBtn, ZoomImg },
    })
], FileListSliderModal);
export default FileListSliderModal;
