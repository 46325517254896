<template lang="pug">
  iframe.zoom-img(v-if="isPdf" :src="`${src}${pdfViewerUrlSettings}`")
  //- q-img.zoom-img(v-else :src="src" spinner-color="blue" contain)
  image-zoom(
      v-else
      img-class="zoom-img"
      :regular="src"
      :click-zoom="true"
      alt="file"
      click-message="Нажмите, для увеличения"
      message-pos="top")
</template>

<script>
import ImageZoom from "vue-image-zoomer"
export default {
  data(){
    return { 
      // pdfViewerUrlSettings: '#toolbar=0&navpanes=0&scrollbar=0'
      pdfViewerUrlSettings: ''
    }
  },
  components: {ImageZoom},
  props: ["src"], // TODO пропсы нормально дотсавать во всех компонентах
  computed: {
    isPdf() {
      return this.src.endsWith(".pdf");
    }
  }
};
</script>

<style lang="scss">
  .zoom-img {
    max-width: 100%;
    max-height: 665px;
    flex-grow: 1;
    border: 1px gray solid;
  }

</style>
